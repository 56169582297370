import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { avaliacaoActions } from 'store/Avaliacoes.store';
import { Perimetria } from './Perimetria';

type Inputs = {
  peso: string;
  altura: number;
  pescoco: number;
  torax: number;
  cintura: number;
  abdomen: number;
  quadril: number;
  bracoEstendidoDireito: number;
  bracoEstendidoEsquerdo: number;
  bracoFlexionadoDireito: number;
  bracoFlexionadoEsquerdo: number;
  antebracoDireito: number;
  antebracoEsquerdo: number;
  punhoDireito: number;
  punhoEsquerdo: number;
  coxaProximalDireita: number;
  coxaProximalEsquerda: number;
  coxaMedialDireita: number;
  coxaMedialEsquerda: number;
  coxaDistalDireita: number;
  coxaDistalEsquerda: number;
  pernaDireita: number;
  pernaEsquerda: number;
  cincuferenciaCintura: number;
  triglicerideos: number;
  hdlColesterol: number;
  pressaoArterialSistolica: number;
  pressaoArterialDiastolica: number;
  glicose: number;
  rce: number;
  rceClassif: string;
  rceDiff: number;
  cinturaClassif: string;
  cinturaDiff: number;
  rcq: number;
  rcqClassif: string;
  rcqDiff: number;
  cincuferenciacinturaclassif: string;
  triglicerideosclassif: string;
  hdlcolesterolclassif: string;
  pressaoarterialclassif: string;
  glicoseclassif: string;
  sindromeMetabolica: string;
};

const Antropometria: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const avaliacao = useAppSelector((state) => state.avaliacoes.antropometria.selected);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(avaliacaoActions.antropometriaById(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!avaliacao) return;
    const _data = _.mapValues(avaliacao, (v) => v.toString());
    reset(_data);
  }, [avaliacao, reset]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      const _data = _.mapValues(data, (v) => (v === '' ? null : v));
      await dispatch(avaliacaoActions.addAntropometria(_data));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    altura,
    cintura,
    quadril,
    cincuferenciaCintura,
    triglicerideos,
    hdlColesterol,
    pressaoArterialDiastolica,
    pressaoArterialSistolica,
    glicose,
  } = watch();
  let sexo = 1;

  useEffect(() => {
    handleRCEChange();
    handleRCQchange();
    handleCintura();
    handleTriglicerideo();
    handleHDL();
    handleGlicose();
    handlePressaoArterial();
  }, [
    altura,
    cintura,
    quadril,
    cincuferenciaCintura,
    triglicerideos,
    hdlColesterol,
    pressaoArterialDiastolica,
    pressaoArterialSistolica,
    glicose,
  ]);

  const handleRCEChange = () => {
    if (cintura === undefined || cintura.toString() === '') return;

    let rce: number = (cintura / altura).toFixed(2) as unknown as number;
    let result = '';
    let diff: number = 0;

    if (rce < 0.49) {
      result = 'Normal';
    } else if (rce < 0.60) {
      result = 'Risco aumentado';
    } else if (rce > 0.60) {
      result = 'Risco alto';
    }else{
      result = 'Sem classificação';
    }

    diff = (rce - 0.49).toFixed(2) as unknown as number;
    
    setValue('rce', rce);
    setValue('rceClassif', result);
    setValue('rceDiff', diff);

    if (cintura) {
      handleCinturaChange();
    }
  };

  const handleCinturaChange = () => {
    let result = '';
    let diff = 0;

    if (cintura < 80 && sexo === 2) {
      result = 'Normal';
    } else if (cintura < 88 && sexo === 2) {
      result = 'Risco aumentado';
    } else if (cintura > 87 && sexo === 2) {
      result = 'Risco muito elevado';
    } else if (cintura < 94 && sexo === 1) {
      result = 'Normal';
    } else if (cintura < 102 && sexo === 1) {
      result = 'Risco aumentado';
    } else if (cintura > 101 && sexo === 1) {
      result = 'Risco muito elevado';
    }

    if (sexo === 2) {
      diff = (cintura - 80).toFixed(2) as unknown as number;
    } else {
      diff = (cintura - 94).toFixed(2) as unknown as number;
    }

    setValue('cinturaClassif', result);
    setValue('cinturaDiff', diff);
  };

  const handleRCQchange = () => {
    if (cintura === undefined || cintura.toString() === '') return;

    const rcq = (cintura / quadril).toFixed(2) as unknown as number;

    let result = '';
    let diff = 0;

    if (rcq < 0.8 && sexo === 2) {
      result = 'Normal';
    } else if (rcq < 0.87 && sexo === 2) {
      result = 'Risco aumentado';
    } else if (rcq > 0.86 && sexo === 2) {
      result = 'Risco muito elevado';
    } else if (rcq < 0.95 && sexo === 1) {
      result = 'Normal';
    } else if (rcq < 1.01 && sexo === 1) {
      result = 'Risco aumentado';
    } else if (rcq > 1 && sexo === 1) {
      result = 'Risco muito elevado';
    }

    if (sexo === 2) {
      diff = (rcq - 0.8).toFixed(2) as unknown as number;
    } else {
      diff = (rcq - 0.95).toFixed(2) as unknown as number;
    }

    setValue('rcq', rcq);
    setValue('rcqClassif', result);
    setValue('rcqDiff', diff);
  };

  const handleCintura = () => {
    const value = cincuferenciaCintura;

    if (Number(value) === 0) return;
    let result = '';

    if (value < 89 && sexo === 2) {
      result = 'Baixo risco';
    } else if (value > 88 && sexo === 2) {
      result = 'Risco elevado';
    } else if (value < 103 && sexo === 1) {
      result = 'Baixo risco';
    } else if (value > 102 && sexo === 1) {
      result = 'Risco elevado';
    }

    setValue('cincuferenciacinturaclassif', result);
    sindMet();
  };

  const handleTriglicerideo = () => {
    const value = triglicerideos;
    if (value === undefined) return;
    if (Number(value) === 0) return;
    let result = '';

    if (value < 150) {
      result = 'Baixo risco';
    } else {
      result = 'Risco elevado';
    }

    setValue('triglicerideosclassif', result);
    sindMet();
  };

  const handleHDL = async () => {
    const value = hdlColesterol;

    if (Number(value) === 0) return;
    let result = '';

    if (value < 50 && sexo === 2) {
      result = 'Risco elevado';
    } else if (value >= 50 && sexo === 2) {
      result = 'Baixo risco';
    } else if (value < 40 && sexo === 1) {
      result = 'Risco elevado';
    } else if (value >= 40 && sexo === 1) {
      result = 'Baixo risco';
    }

    setValue('hdlcolesterolclassif', result);
    sindMet();
  };

  const handlePressaoArterial = () => {
    const value1 = pressaoArterialSistolica;
    const value2 = pressaoArterialDiastolica;
    if (value1 === undefined || value2 === undefined) return;
    if (Number(value1) === 0 || Number(value2) === 0) return;
    let result = '';

    if (value1 >= 130 || value2 >= 85) {
      result = 'Risco elevado';
    } else {
      result = 'Baixo risco';
    }

    setValue('pressaoarterialclassif', result);
    sindMet();
  };

  const handleGlicose = () => {
    const value = glicose;
    if (value === undefined) return;
    if (Number(value) === 0) return;
    let result = '';

    if (value < 111) {
      result = 'Baixo risco';
    } else {
      result = 'Risco elevado';
    }

    setValue('glicoseclassif', result);
    sindMet();
  };

  const sindMet = () => {
    const { cincuferenciacinturaclassif, triglicerideosclassif, hdlcolesterolclassif, pressaoarterialclassif, glicoseclassif } = watch();

    if (
      cincuferenciacinturaclassif === '' &&
      triglicerideosclassif === '' &&
      hdlcolesterolclassif === '' &&
      pressaoarterialclassif === '' &&
      glicoseclassif === ''
    )
      return;
    const resultados = [];
    resultados.push(cincuferenciacinturaclassif);
    resultados.push(triglicerideosclassif);
    resultados.push(hdlcolesterolclassif);
    resultados.push(pressaoarterialclassif);
    resultados.push(glicoseclassif);
    const total = resultados.filter((r) => r === 'Risco elevado').length;

    if (total >= 3) {
      setValue('sindromeMetabolica', 'Risco cardiovascular e metabólico');
    } else {
      setValue('sindromeMetabolica', 'Baixo risco');
    }
  };

  return (
    <>
      <h1 className="font-medium my-5 text-center sm:text-left sm:my-8 md:text-4xl text-lg dark:text-slate-200 uppercase">Antropometria</h1>
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="peso" className="block text-sm font-medium leading-6 text-gray-900">
                        Qual é o seu peso, em Kg?
                      </label>
                      <div className="mt-2">
                        <input type="text" id="peso" className="inputStyles w-full" {...register('peso', { required: false })} />
                      </div>
                      {errors.peso && <p className="text-red-500">*Obrigatório.</p>}
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="altura" className="block text-sm font-medium leading-6 text-gray-900">
                        Qual é a sua altura, em centímetros?
                      </label>
                      <div className="mt-2">
                        <input type="text" id="altura" className="inputStyles w-full" {...register('altura', { required: false })} />
                      </div>
                      {errors.altura && <p className="text-red-500">*Obrigatório.</p>}
                    </div>
                  </div>
                </fieldset>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Perimetria</h2>
                <fieldset>
                  <div className="flex gap-2 mb-3 text-sm font-bold leading-6">
                    <div className="w-3/12 md:w-1/6 flex-none flex items-center justify-start">Circunferência</div>
                    <div className="w-6/12 md:w-5/6 flex items-center justify-center">Descrição</div>
                    <div className="w-3/12 md:w-1/6 flex-none flex items-center justify-around">
                      <div className="w-full grid grid-cols-2 gap-2">
                        <div className="flex justify-center ">D</div>
                        <div className="flex justify-center ">E</div>
                      </div>
                    </div>
                  </div>
                  <Perimetria.Root>
                    <Perimetria.Type>Pescoço</Perimetria.Type>
                    <Perimetria.Desc>
                      Avaliado sentado, posicionar a fita no ponto de menor circunferência do pescoço, logo acima da proeminência laríngea
                      (pomo de Adão).
                    </Perimetria.Desc>
                    <Perimetria.Field>
                      <input type="text" id="pescoco" className="inputStyles w-full text-center" {...register('pescoco')} />
                    </Perimetria.Field>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Tórax</Perimetria.Type>
                    <Perimetria.Desc>Coloca-se a fita na altura dos mamilos.</Perimetria.Desc>
                    <Perimetria.Field>
                      <input type="text" id="torax" className="inputStyles w-full  text-center" {...register('torax')} />
                    </Perimetria.Field>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Cintura</Perimetria.Type>
                    <Perimetria.Desc>
                      Posicionar a fita na metade da distância entre o último arco costal e a crista ilíaca, cerca de 2 cm da cicatriz
                      umbilical.
                    </Perimetria.Desc>
                    <Perimetria.Field>
                      <input type="text" id="cintura" className="inputStyles w-full text-center" {...register('cintura')} />
                    </Perimetria.Field>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Abdomen</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita métrica sobre a cicatriz umbilical.</Perimetria.Desc>
                    <Perimetria.Field>
                      <input type="text" id="abdomen" className="inputStyles w-full text-center" {...register('abdomen')} />
                    </Perimetria.Field>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Quadril</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita métrica no ponto de maior circunferência dos glúteos.</Perimetria.Desc>
                    <Perimetria.Field>
                      <input type="text" id="quadril" className="inputStyles w-full text-center" {...register('quadril')} />
                    </Perimetria.Field>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Braço Estendido</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita no ponto de maior perímetro aparente.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="bracoEstendidoDireito"
                          className="inputStyles w-full text-center"
                          {...register('bracoEstendidoDireito')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="bracoEstendidoEsquerdo"
                          className="inputStyles w-full text-center"
                          {...register('bracoEstendidoEsquerdo')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Braço Flexionado</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita no ponto de maior perímetro aparente.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="bracoFlexionadoDireito"
                          className="inputStyles w-full text-center"
                          {...register('bracoFlexionadoDireito')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="bracoFlexionadoEsquerdo"
                          className="inputStyles w-full text-center"
                          {...register('bracoFlexionadoEsquerdo')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Antebraço</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita no maior perímetro aparente.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="antebracoDireito"
                          className="inputStyles w-full text-center"
                          {...register('antebracoDireito')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="antebracoEsquerdo"
                          className="inputStyles w-full text-center"
                          {...register('antebracoEsquerdo')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Punho</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita sobre os processos estilóides do Rádio e da Ulna.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input type="text" id="punhoDireito" className="inputStyles w-full text-center" {...register('punhoDireito')} />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input type="text" id="punhoEsquerdo" className="inputStyles w-full text-center" {...register('punhoEsquerdo')} />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Coxa Proximal</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita métrica sobre imediatamente abaixo da prega glútea.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaProximalDireita"
                          className="inputStyles w-full text-center"
                          {...register('coxaProximalDireita')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaProximalEsquerda"
                          className="inputStyles w-full text-center"
                          {...register('coxaProximalEsquerda')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Coxa Medial</Perimetria.Type>
                    <Perimetria.Desc>
                      Posicionar a fita no ponto meso-femural. Alguns autores recomendam posicionar a fita na metade da distância entre a
                      linha inguinal e a borda superior da patela.
                    </Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaMedialDireita"
                          className="inputStyles w-full text-center"
                          {...register('coxaMedialDireita')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaMedialEsquerda"
                          className="inputStyles w-full text-center"
                          {...register('coxaMedialEsquerda')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Coxa Distal</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita no ponto 5 cm acima da borda superior da patela.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaDistalDireita"
                          className="inputStyles w-full text-center"
                          {...register('coxaDistalDireita')}
                        />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input
                          type="text"
                          id="coxaDistalEsquerda"
                          className="inputStyles w-full text-center"
                          {...register('coxaDistalEsquerda')}
                        />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                  <Perimetria.Root>
                    <Perimetria.Type>Perna</Perimetria.Type>
                    <Perimetria.Desc>Posicionar a fita métrica no ponto de maior circunferência da perna.</Perimetria.Desc>
                    <Perimetria.Fields>
                      <Perimetria.FieldsItem>
                        <input type="text" id="pernaDireita" className="inputStyles w-full text-center" {...register('pernaDireita')} />
                      </Perimetria.FieldsItem>
                      <Perimetria.FieldsItem>
                        <input type="text" id="pernaEsquerda" className="inputStyles w-full text-center" {...register('pernaEsquerda')} />
                      </Perimetria.FieldsItem>
                    </Perimetria.Fields>
                  </Perimetria.Root>
                </fieldset>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Riscos Cardiorrespiratoórios e Metabólicos</h2>
                <fieldset>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Risco cintura estatura - RCE</h3>
                  <div className="grid grid-cols-3 gap-4 text-sm text-center font-bold leading-6">
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rce" className="block text-sm font-medium leading-6 text-gray-900">
                          Valor
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="rce"
                            className="inputStyles w-full text-center"
                            {...register('rce', { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rceClassif" className="block text-sm font-medium leading-6 text-gray-900">
                          Classificação
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="rceClassif"
                            className="inputStyles w-full text-center"
                            {...register('rceClassif', { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rceDiff" className="block text-sm font-medium leading-6 text-gray-900">
                          Diferença
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="rceDiff"
                            className="inputStyles w-full text-center"
                            {...register('rceDiff', { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Circuferência cintura</h3>
                  <div className="grid grid-cols-3 gap-4 text-sm text-center font-bold leading-6">
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="cintura" className="block text-sm font-medium leading-6 text-gray-900">
                          Valor
                        </label>
                        <div className="mt-2">
                          <input type="text" className="inputStyles w-full text-center" value={cintura} readOnly />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="cinturaClassif" className="block text-sm font-medium leading-6 text-gray-900">
                          Classificação
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="cinturaClassif"
                            className="inputStyles w-full text-center"
                            {...register('cinturaClassif')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="cinturaDiff" className="block text-sm font-medium leading-6 text-gray-900">
                          Diferença
                        </label>
                        <div className="mt-2">
                          <input type="text" id="cinturaDiff" className="inputStyles w-full text-center" {...register('cinturaDiff')} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Risco cintura quadril - RCQ</h3>
                  <div className="grid grid-cols-3 gap-4 text-sm text-center font-bold leading-6">
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rcq" className="block text-sm font-medium leading-6 text-gray-900">
                          Valor
                        </label>
                        <div className="mt-2">
                          <input type="text" id="rcq" className="inputStyles w-full text-center" {...register('rcq')} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rcqClassif" className="block text-sm font-medium leading-6 text-gray-900">
                          Classificação
                        </label>
                        <div className="mt-2">
                          <input type="text" id="rcqClassif" className="inputStyles w-full text-center" {...register('rcqClassif')} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="rcqDiff" className="block text-sm font-medium leading-6 text-gray-900">
                          Diferença
                        </label>
                        <div className="mt-2">
                          <input type="text" id="rcqDiff" className="inputStyles w-full text-center" {...register('rcqDiff')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Síndrome metabólica – Risco cardiovascular e metabólico</h2>
                <fieldset>
                  <div className="grid grid-cols-3 gap-4 text-sm text-center font-bold leading-6">
                    <div className="">Medidas</div>
                    <div className="">Informação</div>
                    <div className="">Resultado</div>
                    <div className="p-1">Circunferência Cintura</div>
                    <div className="p-1">
                      <input
                        type="text"
                        id="cincuferenciaCintura"
                        className="inputStyles w-full text-center"
                        {...register('cincuferenciaCintura')}
                      />
                    </div>
                    <div className="p-1">
                      <input
                        type="text"
                        id="cincuferenciacinturaclassif"
                        className="inputStyles w-full text-center"
                        {...register('cincuferenciacinturaclassif')}
                      />
                    </div>
                    <div className="p-1">Triglicerídeos(mg/dL)</div>
                    <div className="p-1">
                      <input type="text" id="triglicerideos" className="inputStyles w-full text-center" {...register('triglicerideos')} />
                    </div>
                    <div className="p-1">
                      <input
                        type="text"
                        id="triglicerideosclassif"
                        className="inputStyles w-full text-center"
                        {...register('triglicerideosclassif')}
                      />
                    </div>
                    <div className="p-1">HDL Colesterol(mg/dL)</div>
                    <div className="p-1">
                      <input type="text" id="hdlColesterol" className="inputStyles w-full text-center" {...register('hdlColesterol')} />
                    </div>
                    <div className="p-1">
                      <input
                        type="text"
                        id="hdlcolesterolclassif"
                        className="inputStyles w-full text-center"
                        {...register('hdlcolesterolclassif')}
                      />
                    </div>
                    <div className="p-1">Pressão Arterial (mmHg)</div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="p-1">
                        <input
                          type="text"
                          id="pressaoArterialSistolica"
                          className="inputStyles w-full text-center"
                          {...register('pressaoArterialSistolica')}
                        />
                      </div>
                      <div className="p-1">
                        <input
                          type="text"
                          id="pressaoArterialDiastolica"
                          className="inputStyles w-full text-center"
                          {...register('pressaoArterialDiastolica')}
                        />
                      </div>
                    </div>
                    <div className="p-1">
                      <input
                        type="text"
                        id="pressaoarterialclassif"
                        className="inputStyles w-full text-center"
                        {...register('pressaoarterialclassif')}
                      />
                    </div>
                    <div className="p-1">Glicose(mg/dL)</div>
                    <div className="p-1">
                      <input type="text" id="glicose" className="inputStyles w-full text-center" {...register('glicose')} />
                    </div>
                    <div className="p-1">
                      <input type="text" id="glicoseclassif" className="inputStyles w-full text-center" {...register('glicoseclassif')} />
                    </div>
                    <div className="p-1">Síndrome Metabólica</div>
                    <div className="p-1 col-span-2">
                      <input
                        type="text"
                        id="sindromeMetabolica"
                        className="inputStyles w-full text-center"
                        {...register('sindromeMetabolica')}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center rounded-md bg-blue-600 py-2 px-3 text-sm text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
                  disabled={loading}
                >
                  Gravar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Antropometria };
